import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppPages from "./pages/app-pages";
import AuthPages from "./pages/auth-pages";
import PrivateRoute from "./components/shared/PrivateRoute";
import "../src/assets/css/bootstrap/bootstrap.css";
import "../src/assets/css/main.css";
import "../src/assets/css/dashboard.css";
import { APP_PREFIX, AUTH_PREFIX } from "./utils/appConstants";
import Layout from "./components/shared/app-layout/Layout";
import VerifyEmail from "./pages/public-pages/VerifyEmail";
import Home from "./pages/public-pages/home";
import MissionStatement from "./pages/public-pages/MissionStatement";
import PetbondMatch from "./pages/public-pages/petbondMatch";
import PetListing from "./pages/public-pages/petListing";
import ThankYou from "./pages/public-pages/thankYou";
import NotFoundPage from "./pages/public-pages/404";
import FindAVet from "./pages/public-pages/findAvet";
import ViewAdvert from "./pages/public-pages/ViewAdvert";
import PaymentSuccess from "./pages/public-pages/PaymentSuccess";
import PaymentCancel from "./pages/public-pages/PaymentCancel";
import CookiePolicy from "./pages/public-pages/cookiePolicy";
import PrivacyPolicy from "./pages/public-pages/privacyPolicy";
import TermsConditions from "./pages/public-pages/termsConditions";
import FAQ from "./pages/public-pages/faq";
import OnBoardingProcess from "./pages/public-pages/success/onBoardingProcess";
import OnBoardingCancel from "./pages/public-pages/error/onBoardingCancel";
import ForBreeder from "./pages/public-pages/forBreeder";
import AuthProtection from "./components/shared/AuthProtection";
import ViewPet from "./pages/public-pages/ViewPet";
import ContactUs from "./pages/public-pages/contactUs";
import { HelmetProvider } from "react-helmet-async";
import LoginWithLink from "./pages/public-pages/LoginWithLink";
import GeneticTesting from "./pages/public-pages/GeneticTesting";
import AirwayTesting from "./pages/public-pages/AirwayTesting";
import VisitPet from "./pages/public-pages/VisitPet";
import AboutUs from "./pages/public-pages/aboutUs";

function App() {
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  window.captchaConfirmation = (res) => {
    if (res) {
      setIsCaptchaValid(true);
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <BrowserRouter>
        <HelmetProvider>
          <Layout>
            <Routes>
              <Route
                path={`${AUTH_PREFIX}/*`}
                element={
                  <AuthProtection>
                    <AuthPages
                      captcha={{ isCaptchaValid, setIsCaptchaValid }}
                    />
                  </AuthProtection>
                }
              />
              <Route
                path={`${APP_PREFIX}/*`}
                element={
                  <PrivateRoute>
                    <AppPages />
                  </PrivateRoute>
                }
              />
              <Route path="/verify-email/:id" element={<VerifyEmail />} />
              <Route path="/" element={<Home />} />
              {/* <Route path="/test" element={<Test />} /> */}
              <Route path="/mission-statement" element={<MissionStatement />} />
              <Route path="/genetic-testing" element={<GeneticTesting />} />
              <Route path="/airway-testing" element={<AirwayTesting />} />
              <Route path="/petbond-match" element={<PetbondMatch />} />
              <Route path="/pet-listing" element={<PetListing />} />
              <Route path="/pet-listing/:search" element={<PetListing />} />
              <Route
                path="/pet-listing/:breed/:advertName"
                element={<ViewAdvert />}
              />
              <Route
                path="/pet-listing/:breed/:advertName/:petName"
                element={<ViewPet />}
              />
              <Route
                  path="/pet-listing/:breed/:advertName/:petName/visit"
                  element={<VisitPet />}
              />
              <Route path="/thankyou" element={<ThankYou />} />
              <Route path="/find-a-vet" element={<FindAVet />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/payment-cancel" element={<PaymentCancel />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/faq" element={<FAQ />} />
              <Route
                path="/onboarding-process"
                element={<OnBoardingProcess />}
              />
              <Route path="/onboarding-cancel" element={<OnBoardingCancel />} />
              <Route path="/for-breeder" element={<ForBreeder />} />
              <Route path="/login-with-link" element={<LoginWithLink />} />
              <Route
                path="/contact-us"
                element={
                  <ContactUs captcha={{ isCaptchaValid, setIsCaptchaValid }} />
                }
              />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Layout>
        </HelmetProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
